import Auth from '@aws-amplify/auth'
import axios, { CancelTokenSource, InternalAxiosRequestConfig } from 'axios'
import { useCallback, useEffect, useRef } from 'react'
import { refreshJwtToken } from './auth'

export const client = axios.create({
  baseURL: `${process.env['REACT_APP_API_URL']}/api`
})
client.defaults.headers.post['Content-Type'] = 'application/json'

export const openClient = axios.create({
  baseURL: `${process.env['REACT_APP_API_URL']}`
})
openClient.defaults.headers.post['Content-Type'] = 'application/json'

client.interceptors.request.use(
  async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    try {
      const token = await refreshJwtToken()
      if (config.headers) {
        const user = await Auth.currentAuthenticatedUser()
        config.headers.Authorization = `Bearer ${token}`
        config.headers.userEmailAddress = user.attributes.email
      }
      // try without delay calculation because it fucks up the request validation in the backend
      // config.data = window.performance.now()
      return config
    } catch (error) {
      console.error('Failed to authenticate request:', error)
      return Promise.reject(error)
    }
  },
  error => Promise.reject(error)
)

// try without delay calculation because it fucks up the request validation in the backend
// client.interceptors.response.use(
//   async (response: AxiosResponse): Promise<AxiosResponse<any[]>> => {
//     const min = 500
//     const max = 900
//     const delay = Math.random() * (max - min) + min
//     const requestLength =
//       window.performance.now() - response.config.data.requestTime
//
//     if (requestLength > delay) return response
//
//     await new Promise(resolve => setTimeout(resolve, delay - requestLength))
//     return response
//   },
//   error => Promise.reject(error)
// )

// axios cancel token hook for preventing memory leaks
export const useCancelToken = () => {
  const axiosSource = useRef<CancelTokenSource | null>(null)

  const createCancelToken = useCallback(() => {
    axiosSource.current = axios.CancelToken.source()
    return axiosSource.current
  }, [axiosSource])

  useEffect(() => {
    if (axiosSource.current) axiosSource.current.cancel()
  }, [])

  return { createCancelToken }
}
